export const bands = [
    {
        url: 'http://pepperoffensive.com',
        title: 'Pepper Offensive',
    },
    {
        url: 'http://spyboy.info',
        title: 'SpyBoy',
    },
]
